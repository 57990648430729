import React from 'react';
import { Link } from 'react-router-dom';
import { EntrepriseCard, EntrepriseContainer, EntrepriseH1, EntrepriseH2, EntrepriseIcon, EntrepriseP, EntrepriseWrapper } from './PresentationEntrepriseElements';


export const PresentationEntreprise = () => {
    return (
        <EntrepriseContainer id="Entreprise">
            <EntrepriseH1>L'entreprise SOL CENTRE</EntrepriseH1>
            <EntrepriseWrapper>
                <EntrepriseCard>
                    <EntrepriseP>Sol Centre est une entreprise d’ingénierie en géotechnique indépendante. Grâce à une équipe performante et qualifiée, Sol Centre vous accompagne pour la réalisation de vos études de sol G1 pour vos projets de vente de terrains constructibles <Link to="a-propos"><span>En Savoir plus</span></Link><br /><img src="/images/logo_opqibi_solcentre_etude_de_sol_160_107.png" /></EntrepriseP>
                </EntrepriseCard>
            </EntrepriseWrapper>
        </EntrepriseContainer>
    )
}
