import React, { Component } from 'react';
import FileInputComponent from 'react-file-input-previews-base64';
import axios from "axios";
import Helmet from "react-helmet";
//import { AttachFileBtn } from './AttachFileBtn';


export default class DevisForm extends Component {

    constructor(props) {

        super(props);
        this.state = {
            file: [],
            files_number: 0,
            sending: false,
            sending_message: "",
            form: {
                last_first_name: "",
                email: "",
                phone: "",
                dpt: "",
                commune: "",
                ref_cadastrale: "",
                message: "",
                superficie_terrain: "",
                address_terrain: ""
            },
            errors: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleFormInputChange = this.handleFormInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    handleChange(file_arr) {

        this.setState({ file: file_arr });
    }

    handleFormInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    }

    validate(form) {
        // we are going to store errors for all fields
        // in a signle array
        const errors = [];

        if (form.last_first_name.length === 0) {
            errors.push("Name can't be empty");
        }

        if (form.email.length < 5) {
            errors.push("Email should be at least 5 charcters long");
        }
        if (form.email.split("").filter(x => x === "@").length !== 1) {
            errors.push("Email should contain a @");
        }
        if (form.email.indexOf(".") === -1) {
            errors.push("Email should contain at least one dot");
        }

        // if (password.length < 6) {
        //     errors.push("Password should be at least 6 characters long");
        // }

        return errors;
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setState({ sending: true });
        const errors = this.validate(this.state.form);
        if (errors.length > 0) {
            this.setState({ errors });
            return;
        }

        //console.log("submit form");
        let res = await this.uploadFile(this.state.file);
    }

    async uploadFile(File) {
        //return axios.post('http://localhost:4444/api/v1/devis', data_encoded).then(
        // return axios.post('http://localhost:4444/api/v1/devis', {"images":this.state.file, state:this.state}).then(
        return axios.post('https://api-mail.solcentre.fr/api/v1/devis', { "images": this.state.file, form: this.state.form })
            .then((res) => {
                if (res.data === "Success") {
                    this.setState({
                        sending_message: "Merci. Votre message a été envoyé avec succès",
                        sending: false,
                    })
                }
            },
                //err => Promise.reject(err.response.data.errors)
            )
    }

    render() {
        //console.log("nom et prenom dans state = " + this.state.form.last_first_name);
        const { errors } = this.state;
        return (
            <div className='ContactForm'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <img src="/images/logo_opqibi_solcentre_etude_de_sol_160_107.png" />
                            <h2 style={{ marginTop: "14px", fontWeight: "600", fontFamily: "fangsong" }}>Formulaire de contact (Devis)</h2>
                            <div className='contactForm'>
                                {/* <form id='contact-form' noValidate onSubmit={this.handleSubmit}> */}
                                <form onSubmit={this.handleSubmit} method="post">
                                    {errors.map(error => (
                                        <p key={error}>Erreur : {error}</p>
                                    ))}
                                    {/* Row 1 of form */}
                                    <div className='row formRow'>
                                        <div className='col-6'>
                                            <input
                                                type='text'
                                                name='last_first_name'
                                                className='form-control formInput'
                                                placeholder='Nom et Prénom (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></input>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type='email'
                                                name='email'
                                                className='form-control formInput'
                                                placeholder='Votre Courriel (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></input>
                                        </div>
                                    </div>
                                    {/* Row 2 of form */}
                                    <div className='row formRow'>
                                        <div className='col-6'>
                                            <input
                                                type='phone'
                                                name='phone'
                                                className='form-control formInput'
                                                placeholder='Téléphone (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></input>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type='number'
                                                name='dpt'
                                                className='form-control formInput'
                                                placeholder='Votre Département (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></input>
                                        </div>
                                    </div>
                                    {/* Row 3 of form */}
                                    <div className='row formRow'>
                                        <div className='col-6'>
                                            <input
                                                type='text'
                                                name='commune'
                                                className='form-control formInput'
                                                placeholder='Commune du terrain (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></input>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type='text'
                                                name='ref_cadastrale'
                                                className='form-control formInput'
                                                placeholder='Réf. Cadastrale (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></input>
                                        </div>
                                    </div>
                                    <div className='row formRow'>
                                        <div className='col-6'>
                                            <input
                                                type='text'
                                                name='superficie_terrain'
                                                className='form-control formInput'
                                                placeholder='Superficie du terrain'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                            ></input>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type='text'
                                                name='address_terrain'
                                                className='form-control formInput'
                                                placeholder='Adresse du terrain'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                            ></input>
                                        </div>
                                    </div>
                                    {/* Row 2 of form */}
                                    {/* <div className='row formRow'>
                    <div className='col'>
                      <input
                        type='text'
                        name='subject'
                        className='form-control formInput'
                        placeholder='Subject'
                      ></input>
                    </div>
                  </div> */}
                                    {/* Row 3 of form */}
                                    <div className='row formRow'>
                                        <div className='col'>
                                            <textarea
                                                rows={3}
                                                name='message'
                                                className='form-control formInput'
                                                placeholder='Votre message et informations complémentaires (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className='row formRow'>
                                        <div className='col'>
                                            <FileInputComponent
                                                labelText="Ajouter Vos Pièces jointes - si nécessaire"
                                                labelStyle={{ fontSize: 17 }}
                                                imagePreview={false}
                                                multiple={true}
                                                callbackFunction={(file_arr) => { this.handleChange(file_arr) }}
                                                //  
                                                // callbackFunction={(file_arr) => { console.log(file_arr) }}
                                                accept="*"
                                            // accept="image/*"
                                            />
                                            <span>{this.state.file.length > 0 && `${this.state.file.length} fichier(s)`}</span>
                                        </div>

                                    </div>
                                    <button className='submit-btn' type='submit' >{this.state.sending ? "Envoi en cours ..." : "Envoyer"}</button>
                                    <p className="succes"><span className="span_succes">{this.state.sending_message}</span></p>
                                    {/* <button type="submit" className="btn btn-info"> Update File (Send Form) </button> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
};