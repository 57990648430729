import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Footer } from './components/Footer/Footer';
import { FooterSubText } from './components/Footer/FooterElements';
import { Navbar } from './components/Navbar/Navbar';
import GloblStyle from './globalStyles';
import { Home } from './pages/HomePage/Home';
import { Services } from './pages/ServicesPage/Services';
import Contact from './components/Contact/Contact';
import { APropos } from './pages/APropos/APropos';
import Devis from './pages/DevisPage/Devis';
import Question from './pages/QuestionPage/Question';
import { MentionsLegales } from './pages/MentionsLegales/MentionsLegales';


const store = require('./reducers').init();


function App() {
  return (
    <Provider store={store}>
      <Router>
        <GloblStyle />
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/services" exact component={Services} />
          <Route path="/prestations" exact component={Services} />
          {/* <Route exact path='/contactez-nous' component={Contact} /> */}
          <Route exact path='/contactez-nous' component={Devis} />
          <Route exact path='/devis' component={Devis} />
          <Route exact path='/question' component={Question} />
          {/* <Route exact path='/devis' component={Devis} /> */}
          <Route exact path='/a-propos' component={APropos} />
          <Route exact path='/mentions-legales' component={MentionsLegales} />
          {/* <Route exact path='/contactez-nous' component={ContactNetlify} /> */}
        </Switch>
        <Footer />

      </Router>
    </Provider>
  );
}

export default App;
