import React, { Component } from 'react';
import GoogleMap from '../../components/GoogleMaps/GoogleApiWrapper';
import DevisForm from '../../components/Contact/DevisForm';
import Helmet from "react-helmet";
import "./Devis.css";

export default class Devis extends Component {

    render() {
        return (
            <>
                <Helmet>
                    <title>Sol Centre : CONTACTEZ-NOUS & DEVIS</title>
                    <meta name="keywords" content="solcentre, sol centre, Geotechnique, geotechnique, geotechnique loiret, etude de sol, etude de sol G1, vente de terrains constructibles, vente de terrains, l’article L112-21 de la loi ÉLAN,  l’article L112-21, loi ÉLAN, arrêté du 22 juillet 2020 de la loi ÉLAN" />
                    <meta name="description" content="Contactez-nous - Devis : Sol Centre c'est une entreprise locale à taille humaine, pour l'étude de votre sol GI pour vos projets de vente de terrains constructibles" />
                </Helmet>
                <div class="row">
                    <div class="col-md-12">
                        <div className="map">
                            <GoogleMap />
                        </div>
                    </div>
                </div>

                    <DevisForm/>
            </>
        )
    }
}
