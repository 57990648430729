import styled from 'styled-components';

export const ServicesContainer = styled.div`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    background:white;
    margin-top: 20px;

    @media screen and (max-width:768px){
        display:block;
    }

    @media screen and (max-width:480px){
        display:block;
    }

    @media screen and (max-width:510px){
        display:block;
    }
`;

export const ServicesWrapper = styled.div`
    
    margin:0 auto;
    display:grid;
    grid-template-columns : 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    
    

    @media screen and (max-width:1000px){
        grid-template-columns:1fr;
        padding: 0 20px;
        
    }

    @media screen and (max-width:768px){
        grid-template-columns:1fr;
        padding: 0 15px;
    }
`;

export const ServicesCard = styled.div`
    background: #fff;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    border-radius:10px;
    padding:20px;
    box-shadow: 4px 3px 2px #8e555c;
    transition:all 0.2s ease-in-out;

    @media screen and (max-width:480px){
        display:block;
    }

    &:hover{
        transform:scale(1.04);
        transition:all 0.2s ease-in-out;
        cursor:pointer;
    }
`;

export const ServicesIcon = styled.img`
height:160px;
width:160px;
margin-bottom:10px;
`;

export const ServicesH1 = styled.h1`
    font-size:2.5rem;
    text-align:center;
    font-family: serif;
    margin-top: 25px;
    color: #8e555c;
    font-style:italic;

    @media screen and (max-width:480px){
        font-size:2rem;
        margin-top: 15px;
    } 
`;

export const ServicesH2 = styled.h2`
    font-size:1rem;
    margin-bottom:10px;
    text-align:center;
    color:black;
    font-weight:500;
    font-family: sans-serif;
    font-style: italic;
    text-transform: uppercase;
`;

export const ServicesP = styled.p`
    font-size:1rem;
    font-style:italic;
`;

/*****second info section ********/
export const Service2Container = styled.div`
    
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    background:white;
    margin-top: 20px;

    @media screen and (max-width:768px){
        display:block;
    }

    @media screen and (max-width:480px){
        
        display:block;
        
    }

    @media screen and (max-width:510px){
        height: fit-content;
        display:block;
    }
`;

export const Service2Wrapper = styled.div`
    
    margin:0 auto;
    display:grid;
    grid-template-columns : 1fr;
    align-items: center;
    
    @media screen and (max-width:1000px){
        display:block;
        padding: 0px 20px;
    }

    @media screen and (max-width:768px){
        display:block;
        padding: 0px 15px;
    }
`;

export const Service2Card = styled.div`
    background: #fff;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    border-radius:10px;
    padding:30px;
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.2); */
    box-shadow: 4px 3px 2px #8e555c;
    transition:all 0.2s ease-in-out;

    &:hover{
        transform:scale(1.04);
        transition:all 0.2s ease-in-out;
        cursor:pointer;
    }

    @media screen and (max-width:350px){
	height:230px;
    } 
   
`;


export const Service2H1 = styled.h1`
    font-size:2.5rem;
    text-align:center;
    font-family: serif;
    color: #8e555c;
    font-style:italic;

    @media screen and (max-width:480px){
        font-size:2rem;
    } 
`;

export const Service2H2 = styled.h2`
    font-size:1rem;
    margin-bottom:10px;
    text-align:center;
    color:black;
    font-family: sans-serif;
    font-style: italic;
    font-size: larger;
    text-transform: uppercase;
    
`;

export const Service2P = styled.p`
    font-size:1rem;
    font-style:italic;
`;

export const Button = styled.button`

    border-radius:4px;
    background:${({primary}) => (primary ? '#8e555c':'black')};
    white-space:nowrap;
    padding: ${({big}) => (big ? '12px 64px' : '10px 20px')};
    color:#fff;
    font-size:${({fontBig}) => (fontBig ? '20px':'16px')};
    outline:none;
    border:none;
    cursor: pointer;
    margin-top:15px;

    &:hover{
        transition:all 0.3s ease-out;
        background:#fff;
        background: ${({primary})=>(primary ? '#cc2035':'#4B59F7')};
    }

    @media screen and (max-width:960px){
        width:100%;
        margin-top:10px;
        font-size: 14px;
    }
    @media screen and (max-width:400px){
        width:100%;
        margin-top:10px;
        font-size: 10px;
    }
`;

