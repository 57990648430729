import styled from 'styled-components';

export const EntrepriseContainer = styled.div`
    
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    background:white;
    margin-top: 20px;

    @media screen and (max-width:768px){
        display:block;
    }

    @media screen and (max-width:480px){
        
        display:block;
        
    }

    @media screen and (max-width:510px){
        height: fit-content;
        display:block;
    }
`;

export const EntrepriseWrapper = styled.div`
    
    margin:0 auto;
    display:grid;
    grid-template-columns : 1fr;
    align-items: center;
    
    @media screen and (max-width:1000px){
        display:block;
        padding: 0px 20px;
    }

    @media screen and (max-width:768px){
        display:block;
        padding: 0px 15px;
    }
`;

export const EntrepriseCard = styled.div`
    background: #fff;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    border-radius:10px;
    padding:30px;
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.2); */
    box-shadow: 4px 3px 2px #8e555c;
    transition:all 0.2s ease-in-out;

    &:hover{
        transform:scale(1.04);
        transition:all 0.2s ease-in-out;
        cursor:pointer;
    }

    @media screen and (max-width:350px){
	height:230px;
    } 
   
`;

export const EntrepriseIcon = styled.img`
height:160px;
width:160px;
margin-bottom:10px;
`;

export const EntrepriseH1 = styled.h1`
    font-size:2.5rem;
    text-align:center;
    font-family: serif;
    color: #8e555c;
    font-style:italic;

    @media screen and (max-width:480px){
        font-size:2rem;
    } 
`;

export const EntrepriseH2 = styled.h2`
    font-size:1rem;
    margin-bottom:10px;
    text-align:center;
    color:black;
    font-weight:bold;
    font-family: sans-serif;
`;

export const EntrepriseP = styled.p`
    font-size:1rem;
    text-align:center;
    font-style:italic;
`;


