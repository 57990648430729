import React from 'react';
import { InfoSection } from '../../components/InfoSection/InfoSection';
import { homeObjOne, homeObjTwo, homeObjThree } from './Data';
import Helmet from "react-helmet";

export const Services = () => {
    return (
        <>
            <Helmet>
                <title>Sol Centre : NOS PRESTATIONS</title>
                <meta name="keywords" content="solcentre, sol centre, Geotechnique, geotechnique, geotechnique loiret, etude de sol, etude de sol G1, vente de terrains constructibles, vente de terrains, l’article L112-21 de la loi ÉLAN,  l’article L112-21, loi ÉLAN, arrêté du 22 juillet 2020 de la loi ÉLAN" />
                <meta name="description" content="Nos prestations de service : Sol Centre c'est une entreprise locale à taille humaine, pour l'étude de votre sol GI pour vos projets de vente de terrains constructibles"/>
            </Helmet>

            <InfoSection {...homeObjOne} />
            <InfoSection {...homeObjTwo} />
            <InfoSection {...homeObjThree} />   
        </>
    )
}
