//import renderHTML from 'react-render-html';

export const homeObjOne = {
    primary:true,
    lightBg:true,
    imgStart:'',
    lightTopLine:true,
    lightTextDesc:true,
    buttonLabel:'',
    description:"Après la réalisation des Déclarations de travaux / Déclaration d’Intention de commencement de travaux (DT/DICT), obligatoire avant toute intervention sur le terrain, en complément des informations fournies sur les éventuelles servitudes dont votre terrain peut faire l’objet, notre équipe se rendra sur la parcelle afin de réaliser une visite de site et des sondages géotechniques et essais in-situ. Ces sondages et essais géotechniques permettront de reconnaître la nature des terrains (sableux, argileux,…) et de réaliser des prélèvements de sol pour des essais en laboratoire.",
    headLine:'Sondages',
    lightText:true,
    topLine:'1 ère ETAPE',
    img:require('../../images/prelevement.jpg'),
    alt:'sondage',
    start:''
}

export const homeObjTwo = {
    primary:true,
    lightBg:true,
    imgStart:'true',
    lightTopLine:true,
    lightTextDesc:true,
    buttonLabel:'En Savoir Plus',
    description:'Ces essais permettront de caractériser l’aléa retrait-gonflement des sols conformément à l’arrêté du 22 juillet 2020. ',
    headLine:"Essais en laboratoire",
    lightText:true,
    topLine:'2 ère ETAPE',
    img:require('../../images/analyse.jpg'),
    alt:'essais en laboratoire',
    start:'true'
}

export const homeObjThree = {
    primary:true,
    lightBg:true,
    imgStart:'',
    lightTopLine:true,
    lightTextDesc:true,
    buttonLabel:'En Savoir Plus',
    description:"Conformément à l’article 68 de la Loi ÉLAN et des arrêtés et décrets d’application, notre rapport consistera en la réalisation d’une étude géotechnique G1 : <br/>- Phase Étude de site (ES) <br/>- Phase Principes Généraux de Construction (PGC) <br/><br/> Cette mission G1 de type Loi ÉLAN comprendra :<br/>- les hypothèses géotechniques au stade de l’étude préliminaire,<br/>- la caractérisation de l’aléa retrait-gonflement de sol conformément aux prescriptions de la loi ÉLAN,<br/>- les incertitudes et risques géotechniques résiduels ainsi que les risques encourus inhérents à la construction d’une maison individuelle,<br/>- les investigations complémentaires nécessaires au stade de la mission G2 ( à la charge de l’acquéreur du terrain) pour réduire les incertitudes.    ",
    headLine:'Le Rapport',
    lightText:true,
    topLine:'3 ème ETAPE',
    img:require('../../images/rapport.jpg'),
    alt:'le rapport',
    start:''
}