import { FaMagento } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const FooterContainer = styled.div`
    background-color: #8e555c;
    padding: 22px 20px;
    display: flex;
    justify-content: center;
    gap: 30px;
    align-content: space-between;
    margin-top:20px;

    @media screen and (max-width:380px){
        display:block;
    }
`;

export const FooterSubscription = styled.section`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    text-align:center;
    margin-bottom:24px;
    padding:24px;
    color:#fff;
`
export const FooterEltWrapper = styled.div`
    width:40%;
    @media screen and (max-width:600px){
        width:auto;
    }
`;

export const FooterRightsWrapper = styled.div`
     background-color: #8e555c;
    /* padding: 22px 20px; */
    display: flex;
    justify-content: center;
    gap: 30px;
    align-content: space-between;
    /* margin-top:20px; */

    @media screen and (max-width:380px){
        display:block;
    }
`;

export const FooterLink =styled.a`
    color:white;
    text-decoration:none;
    &:hover{
        color:#adb5bd;
        transition:all 0.3s ease;
        text-decoration:none !important;
    }
`

export const FooterH1 = styled.h1`
    font-size: 2.5rem;
    text-align: center;
    font-family: serif;
    margin-top: 25px;
    color: #8e555c;
    font-style: italic;
    &:hover{
        color:#adb5bd;
        transition:all 0.3s ease;
        text-decoration:none !important;
    }
`;

export const FooterH2 = styled.h2`
    font-size: 1.8rem;
    text-align: center;
    font-family: serif;
    margin-top: 25px;
    color: white;
    font-style: italic;
    font-weight: 600;
    &:hover{
        color:#adb5bd;
        transition:all 0.3s ease;
        text-decoration:none !important;
    }
`;

export const FooterSubHeading = styled.p`
    font-family:serif;
    font-size:20px;
    color:#fff;
    text-align:center;
    margin-bottom: 0px !important;
`;

export const FooterSubText = styled.p`
    font-size:20px;
`;

export const EmailLink = styled.a`
color: white;
text-decoration: none;
&:hover{
        color:#adb5bd;
        transition:all 0.3s ease;
        text-decoration:none !important;
    }
`;


export const PhoneLink = styled.a`
color: white;
text-decoration: none;
&:hover{
        color:#adb5bd;
        transition:all 0.3s ease;
        text-decoration:none !important;
    }
`;

export const Form = styled.form`
    display:flex;
    justify-content: center;
    align-items:center;
    margin-bottom:25px;

    @media screen and (max-width:820px){
        flex-direction:column;
        width:80px;
    }
`;

export const FormInput = styled.input`
    padding:10px 20px;
    border-radius:2px;
    margin-right:10px;
    outline:none;
    border: none;
    font-size:16px;
    border:1px solid #fff;

    &::placeholder{
        color:#242424;
    }

    @media screen and (max-width:820px){
        width:100%;
        margin:0 0 16px 0;
    }
`;

export const SocialMedia = styled.section`
    max-width:1000px;
    width:100%;
`

export const SocialMediaWrap = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:70%;
    max-width:700px;
    margin:40px auto 0 auto;
    color:white;

    @media screen and (manx-width:820px){
        flex-direction:column;
    }


`;

export const SocialLogo = styled(Link)`
    color:#fff;
    justify-self:start;
    cursor:pointer;
    text-decoration:none;
    font-size: 2rem;
    display:flex;
    align-items:center;
    margin-bottom:16px;
    `;

    export const SocialIcon = styled(FaMagento)`
        margin-right:10px;
    `;

    export const WebsiteRights = styled.small`
        color:#fff;
        margin-bottom:5px;
        font-size:14px;
        font-style:italic;
    `;

    export const SocialIcons = styled.div`
        display:flex;
        justify-content:space-between;
        align-items:center;
        width:240px;
    `;

    export const SocialIconLink = styled.a`
    color:#fff;
    font-size: 24px;
    `;
