import React, { Component } from 'react';
import axios from "axios";
// import { AttachFileBtn } from './AttachFileBtn';


export default class DevisForm extends Component {

    constructor(props) {

        super(props);
        this.state = {
            sending: false,
            sending_message: '',
            form: {
                last_first_name: "",
                email: "",
                phone: "",
                message: ""
            },
            errors: []
        };

        this.handleFormInputChange = this.handleFormInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleFormInputChange(e) {

        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    }

    validate(form) {

        const errors = [];
        //console.log("last firt name = ".form.last_first_name);
        //console.log("form in validate cont = "+JSON.stringify(form));
        const { last_first_name, email, phone, message, objet } = form;
        if (form.last_first_name.length === 0) {
            errors.push("le nom et prénom est vide");
        }

        if (form.email.length < 5) {
            errors.push("au moins 5 caractères pour l'adresse email");
        }
        if (form.email.split("").filter(x => x === "@").length !== 1) {
            errors.push("l'adresse Email doit contenir un @");
        }
        if (form.email.indexOf(".") === -1) {
            errors.push("L'adresse Email doit contenir au moins un point (.)");
        }

        // if (password.length < 6) {
        //     errors.push("Password should be at least 6 characters long");
        // }

        return errors;
    }

    async handleSubmit(e) {

        e.preventDefault();
        this.setState({ sending: true });
        const errors = this.validate(this.state.form);
        if (errors.length > 0) {
            this.setState({ errors });
            return;
        }

        // let res = await this.uploadFile(this.state.file);
        return axios.post('https://api-mail.solcentre.fr/api/v1/question', { form: this.state.form }).then((res) => {
            if (res.data === "Success") {
                this.setState({
                    sending_message: "Merci. Votre message a été envoyé avec succès",
                    sending: false,
                })
            }
        },
            //err => Promise.reject(err.response.data.errors)
        )
    }

    render() {
        //console.log("nom et prenom dans state = " + JSON.stringify(this.state.form));
        const { errors } = this.state;
        return (
            <div className='ContactForm'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <img src="/images/logo_opqibi_solcentre_etude_de_sol_160_107.png" />
                            <h2 style={{ marginTop: "14px", fontWeight: "600", fontFamily: "fangsong" }}>Formulaire de contact (Question)</h2>
                            <div className='contactForm'>
                                {/* <form id='contact-form' noValidate onSubmit={this.handleSubmit}> */}
                                <form onSubmit={this.handleSubmit} method="post">
                                    {errors.map(error => (
                                        <p key={error}>Erreur : {error}</p>
                                    ))}
                                    {/* Row 1 of form */}
                                    <div className='row formRow'>
                                        <div className='col-6'>
                                            <input
                                                type='text'
                                                name='last_first_name'
                                                className='form-control formInput'
                                                placeholder='Nom et Prénom (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></input>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type='email'
                                                name='email'
                                                className='form-control formInput'
                                                placeholder='Votre Courriel (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></input>
                                        </div>
                                    </div>
                                    {/* Row 2 of form */}
                                    <div className='row formRow'>
                                        <div className='col-6'>
                                            <input
                                                type='phone'
                                                name='phone'
                                                className='form-control formInput'
                                                placeholder='Téléphone (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></input>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type='text'
                                                name='objet'
                                                className='form-control formInput'
                                                placeholder='Objet de la question (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></input>
                                        </div>
                                    </div>
                                    {/* Row 3 of form */}
                                    <div className='row formRow'>
                                        <div className='col'>
                                            <textarea
                                                rows={3}
                                                name='message'
                                                className='form-control formInput'
                                                placeholder='Votre question (requis)'
                                                onChange={(e) => { this.handleFormInputChange(e) }}
                                                required
                                            ></textarea>
                                        </div>
                                    </div>

                                    <button className='submit-btn' type='submit' >{this.state.sending ? "Envoi en cours ..." : "Envoyer"}</button>
                                    <p className="succes"><span className="span_succes">{this.state.sending_message}</span></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
};