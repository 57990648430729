import React, { useState } from 'react';
//import { ArrowFoward, ArrowRight  } from 'react-icons/fa';
//import Video from '../../videos/video-marketing.mp4';
//import Video from '../../videos/video-data.mp4';
import Video from '../../videos/video-sol-centre-2.mp4';
import { Button } from '../../globalStyles';
import { HeroBg, HeroBtnWrapper, HeroContainer, HeroContent, HeroH1, HeroP, VideoBg, ArrowRight, ArrowForward } from './HeroElements';
import { Link } from 'react-router-dom';


export const HeroSection = () => {

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }
    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroH1>Une étude de sol ?</HeroH1>
                <HeroH1>Une seule adresse : SOL CENTRE</HeroH1>
                {/* <HeroH1>SOL CENTRE</HeroH1> */}
                <HeroP>Confiez votre étude géotechnique à des professionnels de proximité SOL CENTRE</HeroP>
                <HeroBtnWrapper>
                    <Link to="/devis">
                        <Button
                            to='/devis'
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            primary='true'
                            dark='true'
                        >
                            Un devis ?  {hover ? <ArrowForward /> : <ArrowRight />}
                        </Button>
                    </Link>
                    
                </HeroBtnWrapper>
                <HeroBtnWrapper>
                    <Link to="/question">
                        <Button
                            to='/question'
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            primary='true'
                            dark='true'
                        >
                            Une question ? {hover ? <ArrowForward /> : <ArrowRight />}
                        </Button>
                    </Link>
                    
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
};



