import styled, { createGlobalStyle } from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { Container } from '../../globalStyles';
import { FaMagento } from 'react-icons/fa';

export const Nav = styled.nav`
background: #8e555c;
height:80px;
//margin-top:-80px; 
display: flex;
justify-content:center;
align-items: center;
font-size:1.2rem;
position:sticky;
top:0;
z-index:999;

@media screen and (max-width:960px){
    transition:0.8 all ease;
}
`;

export const NavbarContainer = styled.div`
    display:flex;
    justify-content:space-between;
    height:80px;
    ${Container}
    z-index:1;
    width: 100%;
    padding : 0 24px;
    max-width:1100px;
`;

export const Logo = styled.img`
    margin-top: 13px;
`;

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self:flex-start;
    cursor:pointer;
    font-size:2rem;
    display:flex;
    align-items:center;
    margin-left:24px;
    font-weight:bold;
    text-decoration:none;

    &:hover {
        text-decoration:none !important;
        color:#adb5bd;
    }
`;

export const LogoOpqibi = styled.img`
    margin-top: 13px;
`;

export const NavLogoOpqibi = styled(LinkR)`

    display : none;
@media screen and (max-width:450px){
    margin-top: 68px !important;
    margin-left: 8px !important;
    display : block;
  }
@media screen and (max-width:769px){
    margin-top: 68px !important;
    margin-left: 8px !important;
    display : block;
  }

    &:hover {
        text-decoration:none !important;
        color:#adb5bd;
    }
`;

export const NavIcon = styled(FaMagento)`
    margin-right:0.5rem;
`;

export const MobileIcon = styled.div`
    display:none;
    color:#fff !important;

    @media screen and (max-width:768px){
        display:block;
        position:absolute;
        top:0;
        right:0;
        transform:translate(-100%, 60%);
        font-size:1.8rem;
        cursor:pointer;
        color:#fff;
    }
`;

export const NavMenu = styled.ul`
    display:flex;
    align-items: center;
    list-style:none;
    text-align:center;
    margin-right:-22px;

    @media screen and (max-width:768px){
        display:flex;
        flex-direction:column;
        width:100%;
        height:90vh;
        position:absolute;
        top:80px;
        left:${({ click }) => (click ? 0 : '-100%')};
        opacity:1;
        transition:all 0.5s ease;
        background:#8e555c
    }

`;

export const NavItem = styled.li`
    // height:80px;
    border-bottom:2px solid transparent;

    &:hover {
        border-bottom:4px solid white;
    }

    @media screen and (max-width:960px){
        width:100%;
        &:hover{
            border:none;
        }
    }
`

export const NavLinks = styled(LinkR)`
    color:#fff;
    display:flex;
    align-items:center;
    text-decoration:none;
    padding:0.5rem 1rem;
    height:100%;

    &:hover{
        color:#adb5bd;
        transition:all 0.3s ease;
        text-decoration:none !important;
    }
    //cursor:pointer;

    //&.active {
      //  border-bottom: 3px solid #01bf71;
    //}

    @media screen and (max-width:960px){
        text-align:center;
        padding:2rem;
        width:100%;
        display:table;

        &:hover{
            color:#adb5bd;
            transition:all 0.3s ease;
            text-decoration:none !important;
        }
    }
`;

export const NavBtn = styled.nav`
display:flex;
    align-items:center;

    @media screen and (max-width:768px){
        display:none
    }
`;

export const NavBtnLink = styled(LinkR)`
    border-radius: 50px;
    background : #01bf71;
    white-space:nowrap;
    padding:10px 22px;
    color: #010606;
    font-size:16px;
    outline:none;
    border:none;
    cursor:pointer;
    transition:all 0.2s ease-in-out;
    text-decoration:none;
    
    &:hover{
        transition:all 0.2s ease-in-out;
        background:#fff;
        color:#010606;
    }
`;

export const PhoneLink = styled.a`
color: white;
text-decoration: none;
&:hover{
        color:#adb5bd;
        transition:all 0.3s ease;
        text-decoration:none !important;
    }
`;

export const EmailLink = styled.a`
color: white;
text-decoration: none;
&:hover{
        color:#adb5bd;
        transition:all 0.3s ease;
        text-decoration:none !important;
    }
`;

export const EltLink = styled.a`
color: white;
text-decoration: none;
&:hover{
        color:#adb5bd;
        transition:all 0.3s ease;
        text-decoration:none !important;
    }
`;

export const BlockInfo = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
`;

export const BlockInfoOpqibi = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
`;

export const BlockInfoOpqibiV2 = styled(BlockInfo)`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    margin-top: 0px;
`;

export const ExternalLink = styled.a`
    color:white;
    text-decoration:none;
    &:hover{
            color: white;
            text-decoration: none;
    }
`
