import React from 'react';
//import { Button } from '../../globalStyles';
import { Link } from 'react-router-dom';
import { ServicesCard, ServicesContainer, ServicesH1, ServicesH2, ServicesIcon, ServicesP, ServicesWrapper } from './FirstInfoSectionElements';
import { Button, Service2Container, Service2Wrapper, Service2H2, Service2P, Service2H1, Service2Card } from './FirstInfoSectionElements';


export const FirstInfoSection = () => {
    return (
        <ServicesContainer>
            <Service2Container>
                <Service2Wrapper>
                    <Service2H1>Pourquoi une étude</Service2H1>
                    <Service2Card>
                        <Service2P>Selon <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000037645134/" target="_blank">l’article L112-21 de la loi ÉLAN,</a> tout vendeur de terrain destiné à la construction d’une maison individuelle, <a href="https://www.georisques.gouv.fr/mes-risques/connaitre-les-risques-pres-de-chez-moi" target="_blank">situé en zone d’aléa moyen ou fort</a> en terme de <a href="https://www.georisques.gouv.fr/risques/retrait-gonflement-des-argiles" target="_blank">retrait-gonflement des argiles</a>, doit fournir une étude géotechnique de type G1 à l’acquéreur.</Service2P>
                    </Service2Card>
                </Service2Wrapper>
                <Service2Wrapper>
                    <br/>
                    <Service2H1>Nos Prestations</Service2H1>
                    <Service2Card>
                        <Service2P>Afin de répondre aux exigences de <a href="https://www.legifrance.gouv.fr/loda/id/LEGIARTI000042377485/2020-10-01/" target="_blank">l’arrêté du 22 juillet 2020 de la loi ÉLAN,</a> et conformément à la norme NFP 94-500 de décembre 2013 définissant le contenu des missions géotechniques, notre prestation comprend la réalisation :</Service2P>
                        <ul className="service-list">
                            <li>d’une visite de site avec sondages géotechniques et essais in-situ</li>
                            <li>d’essais en laboratoire,</li>
                            <li>d’un rapport d’études géotechniques - mission G1 type Loi ÉLAN</li>
                        </ul>
                    </Service2Card>
                </Service2Wrapper>
            </Service2Container>

            <ServicesH1>Les 3 phases de votre étude</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    {/* <ServicesIcon src={Icon1} /> */}
                    <ServicesH2> 1ère Etape: Sondages</ServicesH2>
                    {/* <ServicesP>Après la réalisation des Déclarations de travaux / Déclaration d’Intention de commencement de travaux (DT/DICT), obligatoire avant toute intervention sur le terrain, en complément des informations fournies sur les éventuelles servitudes dont votre terrain peut faire l’objet, notre équipe se rendra sur la parcelle afin de réaliser une visite de site et des sondages géotechniques et essais in-situ ...</ServicesP> */}
                </ServicesCard>
                <ServicesCard>
                    {/* <ServicesIcon src={Icon2} /> */}
                    <ServicesH2>2ème Etape: Essais en laboratoire</ServicesH2>
                    {/* <ServicesP>Ces essais permettront de caractériser l’aléa retrait-gonflement des sols conformément à l’arrêté du 22 juillet 2020. </ServicesP> */}
                </ServicesCard>
                <ServicesCard>
                    {/* <ServicesIcon src={Icon3} /> */}
                    <ServicesH2>3ème Etape: Le Rapport</ServicesH2>
                    {/* <ServicesP>Conformément à l’article 68 de la Loi ÉLAN et des arrêtés et décrets d’application, notre rapport consistera en la réalisation d’une étude géotechnique G1 :<br/>- Phase Étude de site (ES)<br/>- Phase Principes Généraux de Construction (PGC) <br/>Cette mission G1 de type Loi ÉLAN comprendra :
<br/>- les hypothèses géotechniques au stade de l’étude préliminaire,
<br/>- la caractérisation de l’aléa ...
</ServicesP> */}
                </ServicesCard>
            </ServicesWrapper>

            <Link to='/prestations'>
                <Button big fontBig primary="true">
                    LES TROIS PHASES DE VOTRE ÉTUDE EN DÉTAILS
                </Button>
            </Link>
        </ServicesContainer>

    )
}
