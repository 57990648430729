import React from 'react';
import './Contact.css';
import ContactForm from './ContactForm';
import { reset } from 'redux-form';
import axios from 'axios';
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import GoogleMap from '../GoogleMaps/GoogleApiWrapper';
import Helmet from "react-helmet";

class Contact extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            errors: [],
            note: '',
            loading: false
        }

        this.pristine = false;
        this.Send = this.Send.bind(this);
    }

    SetImage = async (image) => {
        await this.setState({ image });
    };

    Send(userData) {

        let { image } = this.state;
        userData = { ...userData, image };
        this.setState({ loading: true });
        this.sendEmail(userData).then(
            submited => {
                toast.success('Votre courriel a été envoyé avec succes MERCI ! ');
                this.props.dispatch(reset('ContactForm'));
                this.setState({ key: 'cleared' })
                this.setState({ note: 'Votre courriel a été envoyé avec succès', loading: false });
                // this.setState({ note: 'Email sent successfully', loading: false });

            },
        ).catch(errors => {
            toast.error('Erreur d\'envoi du message');
            this.setState({ errors, loading: false });
        });
    };

    sendEmail = async emailData => {

        console.log(emailData);

        //return axios.post('http://localhost:4444/api/v1/contact', emailData).then(
        return axios.post('https://api-mail.solcentre.fr/api/v1/contact', emailData).then(
            res => res.data,
            err => Promise.reject(err.response.data.errors)
        )

    };

    render() {
        const { errors } = this.state;
        return (
            <>

                <Helmet>
                    <title>Sol Centre : CONTACTEZ-NOUS & DEVIS</title>
                    <meta name="keywords" content="solcentre, sol centre, Geotechnique, geotechnique, geotechnique loiret, etude de sol, etude de sol G1, vente de terrains constructibles, vente de terrains, l’article L112-21 de la loi ÉLAN,  l’article L112-21, loi ÉLAN, arrêté du 22 juillet 2020 de la loi ÉLAN" />
                    <meta name="description" content="Contactez-nous - Devis : Sol Centre c'est une entreprise locale à taille humaine, pour l'étude de votre sol GI pour vos projets de vente de terrains constructibles" />
                </Helmet>

                <div class="row">
                    <div class="col-md-12">
                        <div className="map">
                            <GoogleMap />
                        </div>
                    </div>
                </div>
                <section id='contact'>

                    <div class="container contact-form">
                    
                        <ContactForm
                            loading={this.state.loading}
                            submitCb={this.Send}
                            errors={errors}
                            SetImage={this.SetImage}
                            pristine={this.pristine}
                            key={this.state.key}
                        />
                    </div>
                    
                    <ToastContainer />
                </section>
            </>
        )
    }
}
export default connect(null, null)(Contact);
