import React, { useState } from 'react';
import { MobileIcon, Nav, Logo, NavbarContainer, NavIcon, NavItem, NavLinks, NavLogo, NavMenu, PhoneLink, EmailLink, BlockInfo, ExternalLink, EltLink, BlockInfoOpqibiV2, NavLogoOpqibi, LogoOpqibi } from './NavbarElements';
import { Container } from '../../globalStyles';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { NavLink } from 'react-router-dom';


export const Navbar = () => {

    const [click, setclick] = useState(false);
    const handleClick = () => setclick(!click);

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav>
                    <NavbarContainer>
                        <NavLogo to='/'>
                            <Logo src={process.env.PUBLIC_URL + '/images/logo-sol-centre-rond-blanc-160.png'} />
                        </NavLogo>
                        {/* <NavLogoOpqibi className="opqibi-logo"  to='/'>
                            <LogoOpqibi src={process.env.PUBLIC_URL + '/images/logo_opqibi_solcentre_etude_de_sol_160_107.png'} />
                        </NavLogoOpqibi> */}
                        <MobileIcon onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </MobileIcon>
                        <NavMenu onClick={handleClick} click={click}>
                            <NavItem>
                                <NavLinks to='/'>
                                    Accueil
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='/prestations'>
                                    Prestations
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='/a-propos'>
                                    A Propos
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                {/* <NavLinks to=''>
                                    Blog
                                </NavLinks> */}
                                <ExternalLink href="https://blog.solcentre.fr/">
                                    Blog
                                </ExternalLink>
                            </NavItem>
                            <NavItem>
                                <NavLinks to='/contactez-nous'>
                                    Contactez-nous
                                </NavLinks>
                            </NavItem>
                            <BlockInfoOpqibiV2>
                                <PhoneLink href="tel:+33238581590" style={{ fontStyle: "italic", fontFamily: "serif" }}> (Appelez nous : 02 38 58 15 90)</PhoneLink>
                                <EmailLink href="mailto:contact@solcentre.fr" style={{ fontStyle: "italic", fontFamily: "serif" }}> (Ecrivez nous : contact@solcentre.fr)</EmailLink>
                                {/* <NavLogo to='/'>
                                    <Logo src={process.env.PUBLIC_URL + '/images/logo_opqibi_solcentre_etude_de_sol_160_107.png'} />
                                </NavLogo> */}
                            </BlockInfoOpqibiV2>
                        </NavMenu>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
}
