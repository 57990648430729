import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../globalStyles'
//import { Button } from 'react-scroll'
import { FooterContainer, FooterEltWrapper, FooterH2, FooterSubHeading, FooterSubText, Form, FormInput, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights, FooterRightsWrapper, FooterLink, EmailLink, PhoneLink } from './FooterElements'

export const Footer = () => {
    return (
        <>
            <FooterContainer>
                <FooterEltWrapper>
                    <FooterSubHeading>
                        <FooterH2>Adresse de l'entreprise</FooterH2>
                        <FooterSubText>
                            SOL CENTRE
                        </FooterSubText>
                        <FooterSubText>
                            4 Rue de la Côte
                        </FooterSubText>
                        <FooterSubText>
                            45510 TIGY
                        </FooterSubText>
                        <FooterSubText>
                            Tél : <PhoneLink href="tel:+33660422593" > 06.60.42.25.93 </PhoneLink> / <PhoneLink href="tel:+33238581590">02.38.58.15.90</PhoneLink>
                        </FooterSubText>
                        <FooterSubText>
                            <EmailLink href="mailto:contact@solcentre.fr">E-mail : contact@solcentre.fr</EmailLink>

                        </FooterSubText>
                        {/* <Form>
                    <FormInput name='email' type='email' placeholder='Votre Courriel' />
                    <Button fontBig>Envoyer</Button>
                </Form> */}


                    </FooterSubHeading>
                </FooterEltWrapper>
                <FooterEltWrapper>
                    <FooterSubHeading>
                        <FooterH2> Liens de navigation </FooterH2>
                        <FooterLink href="/prestations">
                            <FooterSubText>
                                Prestations
                            </FooterSubText>
                        </FooterLink>
                        <FooterLink href="/a-propos">
                            <FooterSubText>
                                A Propos
                            </FooterSubText>
                        </FooterLink>
                        <FooterLink href="/contactez-nous">
                            <FooterSubText>
                                Contactez-nous
                            </FooterSubText>
                        </FooterLink>
                        {/* <FooterLink href="/mentions-legales"> */}
                        <FooterLink href="/mentions-legales">
                            <FooterSubText>
                                Mentions Légales
                            </FooterSubText>
                        </FooterLink>
                        {/* <Form>
                    <FormInput name='email' type='email' placeholder='Votre Courriel' />
                    <Button fontBig>Envoyer</Button>
                </Form> */}


                    </FooterSubHeading>
                </FooterEltWrapper>

                {/* <SocialMedia>
                <SocialMediaWrap>
                    
                </SocialMediaWrap>
            </SocialMedia> */}

            </FooterContainer>
            <FooterRightsWrapper>

                <WebsiteRights>SOL CENTRE Copyright © tous droits réservés 2021</WebsiteRights>
                <WebsiteRights>-</WebsiteRights>
                <WebsiteRights>SIRET : 89237523900019</WebsiteRights>
            </FooterRightsWrapper>
        </>
    )
}


