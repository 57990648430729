import React from 'react';
import { FirstInfoSection } from '../../components/FirstInfoSection/FirstInfoSection';
import { HeroSection } from '../../components/HeroSection';
// import { InfoSection } from '../../components/InfoSection/InfoSection';
import { PresentationEntreprise } from '../../components/PrensentationEntreprise/PresentationEntreprise';
// import { homeObjOne, homeObjTwo, homeObjThree } from './Data';
import Helmet from "react-helmet";


export const Home = () => {
    return (
        <>
            <Helmet>
                <title>Accueil SOL CENTRE</title>
                <meta name="keywords" content="solcentre, sol centre, Geotechnique, geotechnique, geotechnique loiret, etude de sol, etude de sol G1, vente de terrains constructibles, vente de terrains, l’article L112-21 de la loi ÉLAN,  l’article L112-21, loi ÉLAN, arrêté du 22 juillet 2020 de la loi ÉLAN" />
                <meta name="description" content="Accueil : Sol Centre c'est une entreprise locale à taille humaine, pour l'étude de votre sol GI pour vos projets de vente de terrains constructibles"/>
            </Helmet>
            <HeroSection />
            <PresentationEntreprise />
            <FirstInfoSection />
        </>
    )
}
