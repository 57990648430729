import React from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser'; 
import { Button } from '../../globalStyles';
import { Container } from '../../globalStyles';
import { Heading, Img, ImgWrapper, InfoColumn, InfoRow, InfoSec, Subtitle, TextWrapper, TopLine } from './InfoSectionElements';



export const InfoSection = ({ primary,lightBg, imgStart, lightTopLine, lightTextDesc,description, topLine, lightText, headLine, buttonLabel, img, alt, start}) => {
    return (
        <>
            <InfoSec lightBg={lightBg}>
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headLine}</Heading>
                                <Subtitle lightTextDesc={lightTextDesc}>{ReactHtmlParser(description)}</Subtitle>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <ImgWrapper start={start}>
                                <Img src={img.default} alt={alt} />
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

 