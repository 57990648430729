import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { ProjectInput } from '../FormElements/ProjectInput';
import { ProjectTextArea } from '../FormElements/ProjectTextArea';
import { ImgFileUpload } from '../FormElements/ImgFileUpload';
import './ContactForm.css';


class ContactForm extends React.Component {

    state = {
        imageState: false
    }

    render() {

        const {
            handleSubmit,
            pristine,
            submitting,
            submitCb,
            valid,
            SetImage,
            loading
        } = this.props;

        return (
            <>
                <form onSubmit={handleSubmit(submitCb).bind(this)}
                    onClick={this.resetValues}>
                    <h3>Demande de DEVIS</h3>
                    <div class="row">

                        <div class="col-md-3">
                            <h5>Coordonnées :</h5>
                            <Field
                                name="nom_prenom"
                                type="text"
                                label='Nom et Prénom (requis)'
                                className='form-control'
                                component={ProjectInput}
                            />

                            <Field
                                name="phone"
                                type="phone"
                                label='téléphone (requis)'
                                className='form-control'
                                component={ProjectInput}
                            />

                            <Field
                                name="email"
                                type="email"
                                label='Votre Courriel (requis)'
                                className='form-control'
                                component={ProjectInput}
                            />

                            
                        </div>
                        <div class="col-md-3">
                                <h5>Terrain à vendre : </h5>
                                <Field
                                name="departement"
                                type="number"
                                label='Votre Département (requis)'
                                className='form-control'
                                component={ProjectInput}
                            />
                             <Field
                                name="commune"
                                type="text"
                                label='Votre Commune (requis)'
                                className='form-control'
                                component={ProjectInput}
                            />
                             <Field
                                name="ref_cadastrale"
                                type="text"
                                label='Réf cadastrale (requis)'
                                className='form-control'
                                component={ProjectInput}
                            />
                        </div>
                        <div class="col-md-6">
                            <h5>Votre message et informations complémentaires</h5>
                            <Field
                                name="message"
                                type="text"
                                label='Votre Message (requis)'
                                rows='6'
                                className='form-control'
                                component={ProjectTextArea}
                            />
                            
                        </div>
                    </div>
                    <div class="row" style={{marginBottom:"0px"}}>

                        <div class="col-md-6">
                        <Field
                                name="image"
                                label='Image'
                                className='form-control'
                                component={ImgFileUpload}
                                props={{
                                    changedImage: (e) => {
                                        SetImage(e);
                                        this.setState({
                                            imageState: true
                                        })
                                    },
                                    checkImageState: (e) => {
                                        if (e === 'selected') {
                                            this.setState({
                                                imageState: true
                                            });
                                        } else {
                                            this.setState({
                                                imageState: false
                                            });
                                        }
                                    },
                                }}
                                key={this.props.key}
                            />
                        </div>
                        <div class="col-md-6">
                        {
                                loading ?
                                    <button
                                        className='btn btn-primary btnContact'
                                        type="button"
                                        disabled={true}
                                    >
                                        En cours d'envoi...
</button>
                                    :
                                    <button
                                        className='btn btn-primary btnContact'
                                        type="submit"
                                        disabled={!valid || pristine || submitting}
                                    // disabled={!valid || pristine || submitting || !this.state.imageState}
                                    >
                                        Envoyez
</button>
                            }
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

const validate = values => {
    const errors = {};
    if (!values.nom_prenom) {
        errors.nom_prenom = 'Saisir vos nom et prénom !';
    }
    if (!values.phone) {
        errors.phone = 'Saisir votre numéro de téléphone!';
    }
    if (!values.departement) {
        errors.departement = 'Saisir votre departement !';
    }
    if (!values.commune) {
        errors.commune = 'Saisir votre commune !';
    }
    if (!values.ref_cadastrale) {
        errors.ref_cadastrale = 'Saisir votre référence cadastrale !';
    }
    if (!values.nom_prenom) {
        errors.nom_prenom = 'Saisir vos nom et prénom !';
    }
    if (!values.email) {
        errors.email = 'Saisir votre courriel !';
    }
    if (!values.message) {
        errors.message = 'Saisir votre message !';
    }
    return errors;
}
export default reduxForm({
    form: 'ContactForm',
    validate
})(ContactForm)