import React from 'react';
import styled from 'styled-components';
import Helmet from "react-helmet";
import { Link } from 'react-router-dom';

export const APropos = () => {

    const Wrapper = styled.div`
    width: 70%;
    `;
    const Container = styled.div`
    display:grid;
    grid-template-columns:70%;
    grid-template-rows : 1fr 320px;
    height:100%;
    justify-content: center;
    @media screen and (max-width:1000px){
        grid-template-rows : 1fr 500px;
    }
    @media screen and (max-width:800px){
        grid-template-rows : 1fr 1fr;
    }
    `;

    const FlexRowBloc = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    gap: 70px;
    justify-content: center;
    margin-top: 45px;
    margin-bottom: 60px;
    flex-wrap: wrap;

    @media screen and (max-width:780px){
        width:100%;
        gap:20px;
        margin-top: 15px;
        margin-bottom: 25px;

    }
    
    
    `;

    const BlocCardImg = styled.div`
    
    `;

    const CardImg = styled.img`
    box-shadow: 5px 7px 5px #8e555c;
    background: transparent;
    max-height: 500px;
    `;

    const BlocCardFlexColumnText1 = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    /* align-items: center; */
    margin-top: 25px;
    `;

    const BlocCardFlexColumnText2 = styled.div`
display: flex;
flex-direction:column;
justify-content: center;
/* align-items: center; */
align-items: center;
`;


    const H2Bloc = styled.h2`
        text-transform: uppercase;
    font-style: italic;
    /* font-size: revert; */
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 8px;
    font-family: monospace, sans-serif;
    
    `;

    const Separator = styled.div`
        height: 4px;
    background: #8e555c;
    width: 75px;
    margin-bottom: 30px;
    `;



    const FlexColumnBloc = styled.div`
    flex-direction: column;
    justify-content: center;
    width: 60%;

`;

    const BlocCardText = styled.div`
    width: 65%;

    @media screen and (max-width:780px){
        width:95%;
    }
    
    @media screen and (max-width:500px){
        width:100%;
    }
`;

    const Paragraphe = styled.p`

`;



    return (
        <>
            <Helmet>
                <title>Sol Centre : A Propos</title>
                <meta name="keywords" content="solcentre, sol centre, Geotechnique, geotechnique, geotechnique loiret, etude de sol, etude de sol G1, vente de terrains constructibles, vente de terrains, l’article L112-21 de la loi ÉLAN,  l’article L112-21, loi ÉLAN, arrêté du 22 juillet 2020 de la loi ÉLAN" />
                <meta name="description" content="A Propos : Sol Centre c'est une entreprise locale à taille humaine, pour l'étude de votre sol GI pour vos projets de vente de terrains constructibles" />
            </Helmet>
            <Container>
                <FlexRowBloc>
                    <BlocCardImg>
                        <CardImg src={process.env.PUBLIC_URL + '/images/image_a_propos.png'} alt="" />
                    </BlocCardImg>
                    <BlocCardFlexColumnText1>
                        <H2Bloc> à propos de nous ? </H2Bloc>
                        <div className="separator"></div>
                        <Separator />
                        <BlocCardText>
                            Sol Centre c'est une entreprise locale à taille humaine, pour l'étude de votre sol GI <br /><img src="/images/logo_opqibi_solcentre_etude_de_sol_160_107.png" />
                        </BlocCardText>
                    </BlocCardFlexColumnText1>
                </FlexRowBloc>
                <BlocCardFlexColumnText2>
                    <BlocCardText>
                        <Paragraphe>
                            Après avoir acquis une solide expérience technique, commerciale et de gestion d’équipe pendant près de 15 ans, poussés par un désir d’indépendance, nous avons créé Sol Centre, bureau d’études géotechnique spécialisé dans la réalisation des études de sol G1 Loi ÉLAN.
                            Notre objectif est d’apporter une réponse locale, adaptée et réactive à tout vendeur de terrain concerné par l’application de la loi ÉLAN, dans les départements du Loiret, du Loir et Cher, et du Cher, ainsi que sur les départements limitrophes.

                            Notre savoir-faire et notre équipe à taille humaine feront la différence pour vous accompagner dans votre projet.
                            Choisir Sol Centre pour votre étude de Sol G1, c’est avoir la garantie d’avoir une démarche qualitative d’étude de sol G1.

                            Vous habitez dans le Loiret, le Loir-et Cher ou le Cher, vous êtes un particulier et vous avez besoin d’un étude de sol pour la vente de votre terrain, faites appel à nous <Link to="/devis" style={{ fontWeight: "600", fontStyle: "italic", color: "#8e555c" }}>DEMANDE DE DEVIS</Link>
                        </Paragraphe>
                    </BlocCardText>
                </BlocCardFlexColumnText2>
            </Container>
        </>
    )
}
